import Box from '@mui/material/Box'

function AIDidYouMean() {
  return (
    <Box>
        <p>EmailDetective.io continues to evolve, making email validation smarter and more accurate. We’ve recently added three new fields to our API <code>did_you_mean</code>, <code>first_name</code>, and <code>last_name</code> to improve data quality and usability. These enhancements leverage AI to intelligently process and analyze email addresses, providing high-confidence insights.</p>

        <h2>New Fields and Their Purpose</h2>

        <ul>
        <li><strong><code>did_you_mean</code></strong>: This field suggests corrections for common typos in email addresses. For example, if an input email is <code>something@new.cm</code>, the API might return <code>something@new.com</code> as a likely intended address.</li>
        <li><strong><code>first_name</code> & <code>last_name</code></strong>: These fields attempt to extract user names from email addresses when possible. If the email <code>jane.doe@example.com</code> is provided, the API will return <code>"Jane"</code> and <code>"Doe"</code>.</li>
        </ul>

        <h2>How AI Powers These Features</h2>

        <p>Our AI models analyze email structures, common patterns, and linguistic data to deliver intelligent suggestions and name extractions. By recognizing frequent typos, domain errors, and name formats, the system improves email validation accuracy while minimizing false positives.</p>

        <h2>Benefits of These Enhancements</h2>

        <ul>
        <li>Reduces user entry errors by offering corrections to mistyped emails.</li>
        <li>Enhances personalization by extracting user names directly from emails.</li>
        <li>Improves data quality and integrity, leading to better engagement and fewer bounced emails.</li>
        </ul>

        <p>With these new AI-powered capabilities, EmailDetective.io makes email validation more efficient and reliable. Try the updated API today and experience the improvements firsthand!</p>

    </Box>
    )
}


export default AIDidYouMean